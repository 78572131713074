<template>
  <div>
    <new-perseu-card class="py-5 text-center">
      <div class="body-1">
        <div>
          <v-avatar size="100" class="mb-3">
            <v-img :src="userPhoto" />
          </v-avatar>
          <div class="mb-3">{{ userName }}</div>
        </div>
        <template v-if="subscriptionData.planName">
          <div class="font-weight-bold">
            Plano atual: {{ subscriptionData.planName }}
          </div>
          <div class="my-2">
            <subscription-status-chip :status="subscriptionData.status" />
          </div>
          <div v-if="subscriptionData.nextBillingTime">
            Próxima cobrança:
            {{ subscriptionData.nextBillingTime | formatDateFriendly }}
          </div>
          <div>
            Valor mensal:
            {{ subscriptionData.price }}
          </div>
          <v-divider class="my-5" />
          <div class="d-flex flex-column align-center justify-center">
            <v-btn
              class="mb-5"
              color="secondary"
              large
              rounded
              elevation="0"
              :disabled="isCancelled"
              @click="redirectToPlansPage"
            >
              Alterar plano
            </v-btn>
            <v-btn
              text
              color="secondary"
              class="text-decoration-underline"
              :disabled="isCancelled"
              @click="cancelSubscription"
            >
              Cancelar assinatura
            </v-btn>
          </div>
        </template>
        <template v-else>
          <div class="d-flex align-center justify-center body-2">
            <v-icon color="warning" class="mr-3">pending_actions</v-icon>
            Você não possui nenhum plano ativo ainda
          </div>
          <v-btn
            large
            rounded
            color="secondary"
            elevation="0"
            class="mt-3"
            @click="redirectToPlansPage"
          >
            Contratar plano
          </v-btn>
        </template>
      </div>
    </new-perseu-card>
    <new-perseu-card title="Histórico de inscrições" class="mt-3">
      <v-list v-if="subscriptionsHistory.length > 0" two-line>
        <v-list-item
          v-for="subscription in subscriptionsHistory"
          :key="subscription.subscriptionId"
          class="border-bottom-grey"
        >
          <v-list-item-icon class="mr-3 mr-sm-8 align-self-center">
            <subscription-status-icon :status="subscription.status" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-2">
              Plano: {{ subscription.productTypeFriendly }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Iniciado em:
              {{ subscription.createdAt | formatDateTimeFriendly }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Valido até:
              {{ subscription.validUntil | formatDateTimeFriendly }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <empty-list v-else />
    </new-perseu-card>
  </div>
</template>

<script>
import {
  getCurrentSubscriptionData,
  getSubscriptionsHistory,
  renewLoggedUser,
  sendError,
} from "@/services/external/paypal-service";

export default {
  name: "Me",
  components: {
    SubscriptionStatusChip: () =>
      import(
        "@/domains/subscriptions/presentation/components/SubscriptionStatusChip"
      ),
    SubscriptionStatusIcon: () =>
      import(
        "@/domains/subscriptions/presentation/components/SubscriptionStatusIcon"
      ),
  },
  data: () => ({
    subscriptionData: {},
    subscriptionsHistory: [],
  }),
  computed: {
    userPhoto() {
      return (
        this.$store.getters["login/photo"] || require("@/assets/no-photo.svg")
      );
    },
    userName() {
      return this.$store.getters["login/userName"];
    },
    isCancelled() {
      return this.subscriptionData.status === "CANCELED";
    },
  },
  created() {
    this.fillSubscriptionData();
    this.fillSubscriptionsHistory();
    if (this.$route.query.success === "true") {
      this.refreshUserDataAndSubscriptions();
    } else if (this.$route.query.success === "false") {
      this.onSubscriptionFailure("Erro ao realizar assinatura stripe!");
    }
  },

  methods: {
    async onSubscriptionFailure(error) {
      // await sendError(error);
      this.$errorAlert({
        title: "Erro ao realizar pagamento",
        message: "O pagamento não foi realizado. Tente novamente mais tarde.",
      });
    },
    async refreshUserDataAndSubscriptions() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await renewLoggedUser();
        this.$store.commit("login/set", data);
        this.$successAlert({
          title: "Plano ativado",
          message: "Comece a utilizar as funcionalidades agora mesmo",
        });
        this.$router.push({ name: "home" });
      } catch (error) {
        await sendError(error.stack);
        this.$warningAlert({
          message:
            "Seu pagamento foi realizado, mas algo aconteceu de errado. Já reportamos o erro para que seja solucionado o mais breve possível.",
        });
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },

    async fillSubscriptionData() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await getCurrentSubscriptionData();
        this.subscriptionData = data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async fillSubscriptionsHistory() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await getSubscriptionsHistory();
        this.subscriptionsHistory = data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    redirectToPlansPage() {
      this.$router.push({ name: "subscriptions.plans" });
    },
    cancelSubscription() {
      this.$router.push({
        name: "subscriptions.cancellation",
        query: { validUntil: this.subscriptionData.nextBillingTime },
      });
    },
  },
};
</script>

<style scoped></style>
